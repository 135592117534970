.nav {
  height: 68px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  box-sizing: border-box;

  position: fixed;
  top: 0;
  z-index: 1;
  background: rgb(20, 20, 20);
  background: linear-gradient(
    180deg,
    rgba(20, 20, 20, 1) 0%,
    rgba(20, 20, 20, 0) 100%
  );

  transition: background 200ms ease-in;

  &__logo {
    height: 24px;
    object-fit: contain;
    transition: transform 150ms;

    &:hover {
      transform: scale(1.08);
    }
  }

  &__black-bg {
    background-color: rgb(20, 20, 20);
  }
}
