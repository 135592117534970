@import "../../vars";

$posterWidth: 250px;
$posterFeatureWidth: 166px;

.row {
  margin-left: 10px;
  margin-bottom: 20px;

  &__title {
    margin-left: 20px;
    font-size: x-large;
    font-weight: bold;
  }

  &__posters {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px 20px 36px 20px;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__poster {
    scroll-snap-align: end;
    margin-right: 15px;
    transition: transform 450ms;
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }

    &__image {
      width: 100%;
      object-fit: contain;
      max-height: 250px;
      width: $posterWidth;
      border-radius: 5px;
      box-shadow: $shadow;
      &:last-child {
        margin-right: 0;
      }

      &.featured {
        width: $posterFeatureWidth;
      }
    }

    &__title {
      width: $posterWidth;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      color: white;
    }

    &__overview {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      bottom: 2px;
      width: 100%;
      color: white;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 0 0 5px 5px;

      &.hidden {
        visibility: hidden;
      }

      > * {
        width: 100%;
        word-wrap: break-word;
      }

      &__title {
        font-weight: bold;
        font-size: large;
        line-height: 150%;
        margin-bottom: 10px;
      }

      &__genres {
        font-size: small;

        &__item {
          margin-right: 5px;

          &::after {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: rgba(245, 245, 245, 0.75);
            position: relative;
            top: -1px;
            margin-left: 5px;
          }

          &:last-child::after {
            display: none;
          }
        }
      }
    }
  }

  &__trailer {
    width: 50%;
    padding: 20px;
    margin: 0 auto;
    box-shadow: $shadow;
    object-fit: contain;
    border-radius: 8px;
    background-color: #181818;

    @media screen and (max-width: $breakpointTablet) {
      width: 88%;
    }

    iframe {
      @media screen and (max-width: $breakpointPhone) {
        height: 200px;
      }
    }
  }
}

.no-poster {
  background-color: darkslategrey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
