@import "../../vars";

.banner {
  color: white;
  object-fit: contain;
  height: 35.25vw;
  position: relative;
  margin-bottom: 1rem;

  @media screen and (max-width: $breakpointTablet) {
    height: 70vh;
  }

  &__contents {
    margin-left: 50px;
    position: absolute;
    bottom: 15%;
    width: 40%;

    @media screen and (max-width: $breakpointTablet) {
      width: 80%;
      margin: 0 auto;
      margin-left: 10%;
    }
  }

  &__title {
    font-size: 3rem;
    font-weight: bold;
    line-height: 5rem;
    text-shadow: 2px 2px 4px rgb(0, 0, 0, 45%);

    @media screen and (max-width: $breakpointTablet) {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    @media screen and (max-width: $breakpointPhone) {
      font-size: 1.75rem;
      line-height: 1.75rem;
    }
  }

  &__description {
    font-weight: 400;
    line-height: normal;
    width: 100%;
    overflow: hidden;
    font-size: 1.2vw;
    text-shadow: 2px 2px 4px rgb(0, 0, 0, 45%);
    margin-bottom: 1.4rem;

    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;

    @media screen and (max-width: $breakpointTablet) {
      font-size: 0.8rem;
      line-height: 0.8rem;
    }
  }

  &__button {
    cursor: pointer;
    color: white;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    background-color: rgba(51, 51, 51, 0.5);

    @media screen and (max-width: $breakpointTablet) {
      margin: 10px auto;
      display: block;
      font-size: medium;
      padding: 0.8rem 2rem;
    }

    &.play {
      color: #000;
      background-color: #e6e6e6;
    }

    &:hover {
      color: #000;
      background-color: #e6e6e688;
      transition: all 0.2s;
    }
  }

  &__trailer {
    width: 45%;
    position: absolute;
    right: 60px;
    bottom: 15%;
    border-radius: 8px;
    padding: 5px;
    background-color: #141414;
    box-shadow: $shadow;
    z-index: 10;
    iframe {
      height: 20vw;
    }

    @media screen and (max-width: $breakpointDesktop) {
      iframe {
        height: 255px;
      }
    }

    @media screen and (max-width: $breakpointTablet) {
      position: relative;
      width: 88%;
      margin: 0 auto;
      top: 100px;
      left: 1%;
    }

    @media screen and (max-width: $breakpointPhone) {
      iframe {
        height: 180px;
      }
    }
  }

  &__fade-bottom {
    height: 5.5rem;
    width: 100%;
    background-image: linear-gradient(180deg, transparent, #1414145b, #141414);
    position: absolute;
    bottom: 0;

    @media screen and (max-width: $breakpointDesktop) {
      height: 2.2rem;
    }
  }
}
